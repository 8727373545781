<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">
    <!-- <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert> -->

    <div id="user-add">

      <vx-card title="Nouvel Utilisateur" class="mb-base">
         <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="UserPlusIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Nouvel Utilisateur</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
          {{personnelData+' ...'}}
            <vs-select autocomplete label="personnel" class="ligne-w" v-model="idPersonnel" >
              <vs-select-item :v-for="item in personnelData" :key="item" :value="item" :text="item"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('personnel')">{{ errors.first('personnel') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            {{fonctionData+' ...'}}
            <vs-select autocomplete label="fonction" class="ligne-w" v-model="idFonction" >
              <vs-select-item :v-for="item in fonctionData" :key="item" :value="item" :text="item"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('fonction')">{{ errors.first('fonction') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="login" class="w-full" type="text" v-validate="'required'" name="loginU" v-model="loginU" />
            <span class="text-danger text-sm" v-show="errors.has('login')">{{ errors.first('login') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="pass" class="w-full" type="text" v-validate="'required'" name="passU" v-model="passU" />
            <span class="text-danger text-sm" v-show="errors.has('pass')">{{ errors.first('pass') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatUser" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="libelle = ''; status = false;">Annuler</vs-button>
          </div>
        </div>      
      </vx-card>

    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      idPersonnel: '',
      loginU:'',
      passU:'',
      photo:'',
      idFonction:''
    }
  },
  computed: {
    personnelData () {
      return this.$store.state.personnel.personnels
    },
    fonctionData () {
      return this.$store.state.fonction.fonctions
    }
  },
  methods: {
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${this.user_data.username}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    getPersonnelData () {
      this.$store.dispatch('personnel/fetchPersonnels')
        .then((res) => {
          console.log('Liste des personnels', res.data.data)
        })
    },
    getFonctionData () {
      this.$store.dispatch('fonction/fetchFonctions')
        .then((res) => {
          console.log('Liste des fonctions', res.data.data)
        })
    },
    // deleteRecord () {
    /* Below two lines are just for demo purpose */
    // this.$router.push({name:'app-user-list'})
    // this.showDeleteSuccess()

    /* UnComment below lines for enabling true flow if deleting user */
    // this.$store.dispatch("userManagement/removeRecord", this.user_data.id)
    //   .then(()   => { this.$router.push({name:'app-user-list'}); this.showDeleteSuccess() })
    //   .catch(err => { console.error(err)       })
    // },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'User Deleted',
        text: 'The selected user was successfully deleted'
      })
    }
  },
  created () {
    this.getPersonnelData()
    this.getFonctionData()
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
